import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo/index'
import Hero from '../sections/main/hero'
import Sections from '../components/Sections/sections'

export default function HomepageTemplate({data, pageContext, location}) {
  const {header, footer} = pageContext
  const heroSection = data?.sanityHomepage?.heroVisibility && data?.sanityHomepage?.heroSection
  const pageNormalContent = data?.sanityHomepage?.pageNormalContent
  const beforeFooter = data?.sanityHomepage?.beforeFooter

	
  return (
    <Layout sitemap={true} sitemapData={beforeFooter} header={header} footer={footer}>
      <Seo data={data?.sanityHomepage?.seo} location={location} />
      {data?.sanityHomepage?.heroVisibility && <Hero data={heroSection} />}
      {
        pageNormalContent && pageNormalContent?.map(
          (item, idx) => <Sections key={idx} section={item} />
        )
      }
    </Layout>
  )
}

export const query = graphql`
  query HomepageTemplateQuery {
    sanityHomepage {
      seo {
        title
        metaUrl
        metaDescription
        metaImage {
          asset {
            altText
            gatsbyImageData
            url
          }
        }
      }
      heroVisibility
      heroSection {
        _rawHeroBackgroundColor(resolveReferences: {maxDepth: 10})
        heroDescription {
          _rawContent(resolveReferences: {maxDepth: 10})
          desktopWidth
          marginBottom
        }
        heroTitle {
          align
          text
        }
        heroImage {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaBtn {
          button {
            ...SanityCtaButton
          }
        }
        sectionHeight
      }
      pageNormalContent {
        internalName
        paddingTopDesktop
        paddingBottomDesktop
        paddingTopMobile
        paddingBottomMobile
        bgImage {
          asset {
            gatsbyImageData
			 }
        }
        _rawBgColor
        content {
          ...SanityOptionTitle
          ...SanityStatsShowcase
          ...SanityPillarCards
          ...SanitySectionDivider
          ...SanityCtaButtonRef
          ...SanityColumnThree
          ...SanityColumnTwo
          ...SanityColumnFour
          ...SanityColumnFive
          ...SanityCardFrontBack
          ...SanityBlockContent
          ...SanityTextCta
          ...SanityJustText
          ...SanityTextLink
          ...SanityCtaButton
          ...SanityMainImage
          ...SanityTutorObj
          ...SanityCardExtended
          ...SanityCardLandscape
          ...SanitySubjectsCardsContainer
          ...SanityUpcomingEvents
          ...SanityVideo
          ...SanityTopSection
          ...SanityReview
        }
      }
    }
  }
`
