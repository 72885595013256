//~ DEPENDENCIES

import React from 'react'
//~ COMPONENTS
import { Container, Box, Flex, Text, Link} from '@chakra-ui/react'
import Images from '../../components/Interactive/image'
import RichContent from '../../components/Collections/RichContent'
//~ MEDIA

//~ MISCELLANEOUS

const MainHero = ({data}) => {
  //? VARIABLES - STATES - PROPS - REFS
  const {heroImage, heroTitle, heroDescription, _rawHeroBackgroundColor} = data
	let sectionHeight = data?.sectionHeight
  if(sectionHeight){
	  sectionHeight+='px'
  }
  const ctaButton = data?.ctaBtn?.button
  let ctaCname = data?.ctaCname || "homepage_hero_cta_button"

  //? CONTEXT

  //? SANITY DATA

  //? FUNCTIONS
  
  //? EFFECTS

  return (
    <Box bg={_rawHeroBackgroundColor.color} h={{base: "100%", md: sectionHeight || 'auto'}} overflow='hidden' className={data?.className}>
      <Container maxW={{base: '100%', md: '960px', lg: '1140px', xl: '1400px'}} px={{base: '0px'}}>
        <Flex justify={{base: 'center', md: 'space-between', lg: 'space-around'}} align={'center'}
              flexWrap={{base: 'wrap', md:'nowrap'}} px={{base: '0px', md: '30px', lg:'0px'}}>
          {/*Hero Text*/}
          <Flex
            order={{base: '2', md: '1'}}
            direction='column'
            align={{base: 'center', md: 'flex-start'}}
            justify='center'
            w={{base: '100%', md: '50%', lg: '44%', xl: '480px'}}
            bgColor={{base: '#FFFFFF', md: 'transparent'}}
            px={{base: '20px', md: '0px'}}
            py={{base: '32px', md: '0px'}}
				mt={{lg: '20px', xl: "0"}}
				mb={{md: "20px",lg: "0", xl: '20px'}}
            ml={{ xl: 'calc(3vw + 40px)'}}
            className='hero-text-cta'
          >
            {
              heroTitle &&
                <React.Fragment>
                  {/*Hero Title Desktop*/}
                  <Flex direction='column'
                        color={{base: "#3d3d3d", md: "white"}}
								fontWeight='700'
                        //display={{base: 'none', md: 'flex'}}
						>
                    {
                      heroTitle.map((title, idx) => (
                        <Text
                          as='h1'
                          key={idx}
                          display='block'
                          fontSize={{base: "30px", md: 'calc(6px + 4.8vw)', lg: '4.6vw', xl: '56px'}}
								  lineHeight={{base: "40px", md: "60px"}}
                          fontFamily='merriweather700'
                          mb={
                          {
                            xl: idx === heroTitle.length - 1 ? '0.7em' : '0.23em',
                            lg: idx === heroTitle.length - 1 ? '0.5em' : '0.1em',
                            md: idx === heroTitle.length - 1 ? '0.4em' : '0em'
                          }
                          }
                          // ml={title.align === 'left'  && [-6]}
                          mr={title.align === 'right' && [0,0,0,0,0,-8]}
                          textAlign={title.align}
                          className={title.className}
                        >
                          {title.text}
                        </Text>
                      ))
                    }
                  </Flex>
                </React.Fragment>
            }
            <Box  w={{base: '100%'}}
                  mb={{base: '20px', md: '0px'}}
						mt={{base: '20px', md: '0px'}}
						className="hero-subtext"
                  sx={{
                    '.raw-content_default > p': {
                      fontSize: '18px',
                      lineHeight: '1.3em',
                      color: '#3d3d3d !important',
                      textAlign: 'center',
                      '@media screen and (min-width: 768px)': {
                        fontSize: '19px',
                        color: 'white !important',
                        textAlign: 'left'
                      },
                      '@media screen and (min-width: 1025px)': {
                        fontSize: '23px'
                      }
                    }
                  }}>
              <RichContent data={heroDescription} fontFamily="sourceSansPro" mb='0px' />
            </Box>
            {
              ctaButton &&
                <Link
                  sx={{
                    '&:hover, &:visited, &:active':{
                      textDecoration: 'none'
                    },
						  '&:hover': {
						  filter: 'brightness(1.1)',
						  transform: 'scale(1.03)',
						  boxShadow: '0px 4px 6px -1px rgba(0, 127, 150, 0.2), 0px 2px 4px -1px rgba(0, 127, 150, 0.1)'
						}
                  }}
                  href={ctaButton.textLink} p='10px 24px'
                  color={{base: ctaButton._rawButtonTextColor.value, md: "#3D3D3D"}} bgColor={{base: ctaButton._rawButtonColor.value, md: "#FFFFFF"}} borderRadius='6px'
                  fontSize='18px' fontFamily='sourceSansPro600' mt={{base: "0px", lg: "15px"}} mb={{base: "0px", md: "0px", lg: "50px"}} data-cname={ctaCname}
						test-id={ctaCname}>{ctaButton.text}</Link>
            }
          </Flex>
          {/*Hero Image*/}
          <Box
               w={{base: '320px', md: '50%', lg: '44%', xl: '42%'}}
               h={{base: '250px', md: '500px', lg: '500px', xl: '560px'}}
               overflow='hidden'
               order={{base: '1', md: '2'}}
               alignSelf={'flex-end'}
               position='relative'
			  		>
            <Images image={heroImage?.asset?.gatsbyImageData} alt={heroImage?.alt} height={'100%'} fillImage={true}/>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default MainHero
