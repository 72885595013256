import React from 'react'
import styled from 'styled-components'
import theme from '../../utils/theme'
import { Container } from '@chakra-ui/react'
import ModuledItem from '../../utils/moduledItem'

const Section = styled.section`
  background: ${p => theme.colors[p.background] ? theme.colors[p.background] : p.background};
  padding: ${p => p.padding ? p.padding : '0'};
  background-image: ${p => p.bgImage ? "url('" + p.bgImage + "')" : ""};
  background-size: auto;
`

export default function Sections({section}) {
  
  const hasTopSection = section?.content.filter(item => item?._type === 'topSection')
  const normal = section?.content.filter(item => item?._type !== 'topSection')
	const sectionId = section?.internalName?.toLowerCase().split(' ').join('-')
	const imgObj = section?.bgImage?.asset?.gatsbyImageData;
	const imgSrc = imgObj?.images?.fallback?.src ?? undefined;
  //? CONTEXT
  
  //? SANITY DATA

  //? FUNCTIONS

  //? EFFECTS

  const getSpecificTopSection = (topSection) => {
     const section = topSection.section[0]
     const sectionType = section.type
     return <h3>{sectionType}</h3>
  }
  return (
    <Section
	  id={sectionId}
	  className="page-section"
      background={section?._rawBgColor?.color ? section?._rawBgColor?.color : 'white'}
	  bgImage={imgSrc ?? undefined}
    >
      {
        hasTopSection.length > 0 && (
            getSpecificTopSection(hasTopSection[0])
        )
      }
      <Container className="page-section-container" position={'relative'} px="0px" pt={{base:section?.paddingTopMobile+'px' || '0px' , lg: section?.paddingTopDesktop+'px' || '0px'}} pb={{base:section?.paddingBottomMobile+'px' || '0px' , lg: section?.paddingBottomDesktop+'px' || '0px'}}>
        {
          normal.map((item, idx) => (
            item !== null && <ModuledItem key={idx} module={item._type} data={item} />
          ))
        } 
      </Container>
    </Section>
  )
}
